<template>
    <div class="mb-2">
        <SJA :itemId ="itemId" :stepId="stepId" :readOnly="readOnly" :workflows="workflows" :objects="objects" />
    </div>
    <div>
        <LogAndResponsibilities :itemId ="itemId" :stepId="stepId" :readOnly="readOnly" />
    </div>
</template>


<script setup>

import LogAndResponsibilities from 'assets.libraries.vue.components.objects.LogAndResponsibilitiesMulti.vue';
import SJA from 'workflow.libraries.vue.components.SJA.vue';

const props = defineProps({
    itemId: Number,
    stepId: Number,
    readOnly:Boolean,
    workflows: Object,
    objects: Object,
}); 
</script>